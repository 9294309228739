<i18n>
ru:
  defaultTitle: Внимание
ua:
  defaultTitle: Увага
us:
  defaultTitle: Attention
</i18n>

<template>
  <motion.ul
    v-if="messages.length > 0"
    class="v-cart-sidebar-messages-wrapper"
  >
    <AnimatePresence>
      <motion.li
        v-for="(message, index) in messages"
        :key="`cart-message-${message.Type}-${index}`"
        class="v-cart-sidebar-message v-d-flex v-flex-column"
        :class="{
          'v-cart-sidebar-message__custom':
            message.Type === MessageType.DefaultCustom ||
            message.Type === MessageType.ImportantCustom ||
            message.Type === MessageType.ImportantNotBlockingCustom
        }"
        :initial="{ opacity: 0, x: -300, scaleY: 1 }"
        :animate="{ opacity: 1, x: 0, scaleY: 1 }"
        :exit="{ opacity: 0, x: -300, scaleY: 0.5 }"
        :transition="{
          duration: 0.4
        }"
        layout
      >
        <div
          class="v-d-flex v-justify-content-between v-align-items-center v-w-100"
          role="alert"
        >
          <div
            class="v-cart-sidebar-message__text v-typography-title-8"
            v-html="
              stringIsNullOrWhitespace(message.Title)
                ? translate('messages.defaultTitle')
                : sanitize(message.Title)
            "
          />
          <div
            v-if="
              message.ResultId &&
              (message.Type === MessageType.Default ||
                message.Type === MessageType.DefaultCustom)
            "
            class="v-cart-sidebar-message__icon v-pointer"
            @click="() => closeMessage(message.ResultId!)"
          >
            <ui-icon
              :size="16"
              color="mono400"
              class-name="v-pointer"
              name="close"
            />
          </div>
        </div>
        <div
          class="v-cart-sidebar-message__text v-typography-text-6"
          v-html="sanitize(message.Text)"
        />
      </motion.li>
    </AnimatePresence>
  </motion.ul>
</template>

<script setup lang="ts">
import type { MessagesV2 } from '~types/clientStore'

import { type GUID, useCommon } from '@arora/common'

import { motion } from 'motion-v'
import { MessageType } from '~api/consts'

const clientStore = useClientStore()
const { sanitize, translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()

const closedMessages = useCookie('closedMessages', {
  default: () => [] as GUID[]
})

function closeMessage(id: GUID): void {
  closedMessages.value.push(id)
}

const messages = computed<MessagesV2[]>(() => {
  return (clientStore.ClientState?.data?.MessagesV2 ?? []).filter(
    (message) =>
      !message.ResultId ||
      !closedMessages.value ||
      !closedMessages.value.includes(message.ResultId)
  )
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-cart-sidebar-messages-wrapper {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .v-cart-sidebar-message {
    border-radius: variables.$BorderRadius;
    background: variables.$Mono200;
    color: variables.$Mono1000;
    padding: 0.85rem;

    display: flex;
    align-items: center;

    &__custom {
      background: variables.$OfferCartMessageTextBackgroundColor;
      color: variables.$OfferCartMessageTextColor;
    }
  }
}
</style>
